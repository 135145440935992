<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Leave Credit Request"
            width="600"
            no-footer
            :closable="closable"
            class="createRequest"
            :before-close="handleReset"
        >
            <Form
                ref="creditForm"
                :model="credit"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row v-if="isAdmin">
                    <Col span="">
                        <FormItem label="Employee">
                            <UserList
                                :user="selectedUser._id"
                                :list-disabled="userListDisabled"
                                @selected-user="handleUserSelected"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="">
                        <FormItem label="Leave Type" prop="requestedStartTime">
                            <v-radio-group v-model="credit.type" :disabled="disableLeaveType">
                                {{ leaves.vl }}<v-radio label="Vacation Leave" value="LEAVE|VL"> </v-radio>
                                {{ leaves.sl
                                }}<v-radio label="Emergency/Sick Leave" value="LEAVE|SL"> {{ leaves.sl }}</v-radio>
                                {{ leaves.al }}
                                <v-radio label="Appreciation Leave" value="LEAVE|AL"> {{ leaves.al }}</v-radio>
                            </v-radio-group>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Credits Requested">
                            <Input
                                v-model="credit.credit_requested"
                                type="number"
                                min="1"
                                max="5"
                                :disabled="isAdmin || isReadOnly"
                                placeholder="Enter something..."
                                style="width: 200px"
                            />
                        </FormItem>
                    </Col>

                    <Col v-if="isAdmin" span="8">
                        <FormItem label="Credits to give">
                            <Input
                                v-model="credit.credit_approved"
                                type="number"
                                :disabled="isReadOnly"
                                min="1"
                                max="5"
                                placeholder="Enter something..."
                                style="width: 200px"
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div>
                <Button
                    v-if="!isAdmin && !isReadOnly && !forEdit"
                    class="pt-btn-3"
                    :loading="isLoading"
                    @click="handleAddLeaveCreditRequest"
                >
                    SUBMIT
                </Button>
                <Button
                    v-if="isAdmin && !credit._id"
                    class="pt-btn-3"
                    :disabled="isReadOnly"
                    :loading="isLoading"
                    @click="adminGrantLeaveCredits"
                >
                    GRANT CREDIT
                </Button>
                <Button
                    v-if="isAdmin && credit._id"
                    class="pt-btn-4"
                    :disabled="isReadOnly"
                    :loading="isLoading"
                    @click="processLeaveCredit"
                >
                    APPROVE
                </Button>

                <Button
                    v-if="isAdmin && credit._id"
                    :disabled="isReadOnly"
                    :loading="isLoading"
                    class="pt-btn-2 danger"
                    style="margin-right: 10px; margin-left: 10px"
                    @click="processReject"
                >
                    REJECT
                </Button>

                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>

                <Button
                    v-if="!isAdmin && forEdit"
                    class="pt-btn-3"
                    :disabled="isReadOnly"
                    :loading="isLoading"
                    @click="processEmployeeCreditUpdate"
                >
                    Update Credit Request
                </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
// import { getLeavesInfo } from '@/services/leave.services';
import {
    adminDoGrantEmployeeCredit,
    adminProcessLeaveCreditForApproval,
    getEmployeeAvailableCredits,
    submitLeaveCreditForApproval,
    updateSubmittedLeaveCredit,
} from '@/components/Requests/Dev/Leave/LeaveCredit/services/credit.services';
import UserList from '@/components/Users/UserList.vue';
import { getLeavesInfo } from '@/services/leave.services';
import { parseInt } from 'lodash';

export default {
    components: { UserList },
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            leaves: {
                al: 0,
                sl: 0,
                vl: 0,
            },
            isReadOnly: false,
            disableLeaveType: false,
            userListDisabled: false,
            creditDetails: null,
            selectedUser: null,
            attendanceLogData: null,
            isLoading: false,
            options: null,
            closable: false,
            credit: {
                type: null,
                credit_requested: 0,
                credit_approved: 0,
                _id: null,
            },
            forEdit: false,
            isDisabled: false,
            showModal: false,
            userId: null,
        };
    },
    created() {
        this.options = {
            disabledDate(date) {
                console.log('date: ', date);
                return Date.now();
            },
        };
    },
    methods: {
        async handleAddLeaveCreditRequest() {
            this.$refs.creditForm.validate((valid) => {
                if (valid) {
                    this.addLeaveCreditRequest();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },
        checkAllowedLeaveCredit() {
            const leaveType = this.credit.type.split('|')[1].toLowerCase();
            return parseInt(this.leaves[leaveType]) + parseInt(this.credit.credit_requested) < 5;
        },

        async addLeaveCreditRequest() {
            if (!this.credit.type) {
                this.$Message.error('Input Leave Type');
            } else if (this.credit.credit_requested < 1) {
                this.$Message.error('Update Credit requested');
            } else if (!this.checkAllowedLeaveCredit()) {
                this.$Message.error('Maximum leaves allowed is 5');
            } else {
                this.isLoading = true;

                try {
                    const data = {
                        type: this.credit.type,
                        credit_requested: parseInt(this.credit.credit_requested),
                    };
                    await submitLeaveCreditForApproval(data);
                    this.$Message.success({ content: 'Credit Request Submitted', duration: 3 });
                    this.$emit('create');
                    this.handleCancel();
                } catch (err) {
                    this.$Message.error({ content: err.data.message, duration: 3 });
                } finally {
                    this.isLoading = false;
                }
            }
        },

        async processLeaveCredit() {
            if (this.credit.credit_approved < 1) {
                this.$Message.error('Update Credit requested');
            } else {
                this.isLoading = true;

                try {
                    const data = {
                        id: this.credit._id,
                        credit_approved: parseInt(this.credit.credit_approved),
                        status: 'approved',
                    };
                    await adminProcessLeaveCreditForApproval(data);
                    this.$Message.success({ content: 'Credit Request Approved', duration: 3 });
                    this.$emit('create');
                    this.handleCancel();
                } catch (err) {
                    this.$Message.error({ content: err.data.message, duration: 3 });
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async processReject() {
            this.isLoading = true;

            try {
                const data = {
                    id: this.credit._id,
                    credit_approved: 0,
                    status: 'rejected',
                };
                await adminProcessLeaveCreditForApproval(data);
                this.$Message.success({ content: 'Credit Request Rejected', duration: 3 });
                this.$emit('create');
                this.handleCancel();
            } catch (err) {
                this.$Message.error({ content: err.data.message, duration: 3 });
            } finally {
                this.isLoading = false;
            }
        },

        async adminGrantLeaveCredits() {
            if (!this.selectedUser) {
                this.$Message.error('Select Employee');
            }
            if (!this.credit.type) {
                this.$Message.error('Input Leave Type');
            } else if (this.credit.credit_approved < 1) {
                this.$Message.error('Update Credit requested');
            } else {
                this.isLoading = true;

                try {
                    const data = {
                        type: this.credit.type,
                        credit_approved: parseInt(this.credit.credit_approved),
                        user: this.selectedUser._id,
                    };
                    console.log('data: ', data);
                    await adminDoGrantEmployeeCredit(data);
                    this.$Message.success({ content: 'Credit Added', duration: 3 });
                    this.$emit('create');
                    this.handleCancel();
                } catch (err) {
                    console.log('err: ', err);
                    this.$Message.error({ content: err.data.message, duration: 3 });
                } finally {
                    this.isLoading = false;
                }
            }
        },

        async processEmployeeCreditUpdate() {
            if (!this.credit.type) {
                this.$Message.error('Input Leave Type');
            } else if (this.credit.credit_requested < 1) {
                this.$Message.error('Update Credit requested');
            } else if (!this.checkAllowedLeaveCredit()) {
                this.$Message.error('Maximum leaves allowed is 5');
            } else {
                this.isLoading = true;

                try {
                    const data = {
                        type: this.credit.type,
                        credit_requested: parseInt(this.credit.credit_requested),
                    };
                    await updateSubmittedLeaveCredit({ id: this.credit._id, data });
                    this.$Message.success({ content: 'Credit Updated', duration: 3 });
                    this.$emit('create');
                    this.handleCancel();
                } catch (err) {
                    console.log('err: ', err);
                    this.$Message.error({ content: err.data.message, duration: 3 });
                } finally {
                    this.isLoading = false;
                }
            }
        },

        openModal(data, fromShiftTable) {
            console.log(data, fromShiftTable);
            this.selectedUser = { _id: null };
            if (!this.isAdmin) this.renderEmployeeLeaves();
            this.isLoading = false;
            this.showModal = true;
        },
        openModalForEditing(data) {
            this.credit.type = data.type;
            this.credit._id = data._id;
            this.credit.credit_requested = data.credit_requested;

            this.disableLeaveType = false;
            this.isLoading = false;
            this.showModal = true;
            this.forEdit = true;
            this.renderEmployeeLeaves();
        },
        openModalForAdminProcessingLeaves(creditDetails) {
            this.forEdit = false;
            this.isLoading = false;
            this.selectedUser = { _id: creditDetails.user._id };
            this.userListDisabled = true;
            this.disableLeaveType = true;
            this.credit.type = creditDetails.type;
            this.credit._id = creditDetails._id;
            this.credit.credit_requested = creditDetails.credit_requested;
            this.credit.credit_approved =
                creditDetails.status === 'pending' ? creditDetails.credit_requested : creditDetails.credit_approved;
            this.isReadOnly = ['approved', 'rejected', 'cancelled'].includes(creditDetails.status);
            this.handleUserSelected(this.selectedUser);
            this.showModal = true;
        },
        openModalForReadOnly(creditDetails) {
            this.isLoading = false;
            this.selectedUser = { _id: creditDetails.user._id };
            this.userListDisabled = true;
            this.credit.credit_requested = creditDetails.credit_requested;
            this.credit.type = creditDetails.type;
            this.disableLeaveType = true;
            this.renderEmployeeLeaves();
            this.showModal = true;
            this.isReadOnly = true;
        },
        handleCancel() {
            this.isLoading = false;
            this.showModal = false;
            this.credit.credit_requested = 0;
            this.selectedUser = { _id: null };
            this.leaves.sl = 0;
            this.leaves.al = 0;
            this.leaves.vl = 0;
            this.$refs.creditForm.resetFields();
        },

        handleConfirm() {
            this.showModal = false;
        },
        async handleUserSelected(user) {
            if (user) {
                const { data: leaves } = await getEmployeeAvailableCredits({ user: user._id });
                this.selectedUser = user;
                this.leaves.sl = leaves['remaining-sick-leave'];
                this.leaves.al = leaves['remaining-appreciation-leave'];
                this.leaves.vl = leaves['remaining-vacation-leave'];
            } else {
                this.selectedUser = null;
                this.leaves.sl = 0;
                this.leaves.al = 0;
                this.leaves.vl = 0;
            }
        },
        async renderEmployeeLeaves() {
            const { data: leaves } = await getLeavesInfo();
            this.leaves.sl = leaves['remaining-sick-leave'];
            this.leaves.al = leaves['remaining-appreciation-leave'];
            this.leaves.vl = leaves['remaining-vacation-leave'];
        },
        handleReset() {
            this.$refs.creditForm.resetFields();
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.createRequest .ivu-modal-body {
    padding: 16px;
}
.createRequest .ivu-modal-footer {
    display: none;
}
.createRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.create-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .create-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
