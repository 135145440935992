<template>
    <div>
        <div>
            <Table
                :columns="tblColumns"
                :data="requestsDataList"
                size="small"
                :loading="isLoading"
                class="requestsList"
            />
        </div>

        <Page
            :total="totalCount"
            :current="currentPage"
            :show-total="true"
            :page-size="limit"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
        <Create ref="approveCreditModal" :is-admin="isAdmin" @create="handleReloadList" />
        <ViewRequestModal ref="ViewRequest" />
        <UpdateRequestModal ref="UpdateRequest" @create="handleReloadList" />
        <DeleteRequestModal ref="DeleteRequest" @create="handleReloadList" />
    </div>
</template>

<script>
import moment from 'moment';
import ViewRequestModal from '@/components/Requests/Dev/Leave/LeaveCredit/ViewRequestModal';
import UpdateRequestModal from '@/components/Requests/Dev/Leave/LeaveCredit/UpdateRequestModal';
import DeleteRequestModal from '@/components/Requests/Dev/Leave/LeaveCredit/DeleteRequestModal';
import { adminProcessLeaveCreditForApproval } from '@/components/Requests/Dev/Leave/LeaveCredit/services/credit.services';
// import ApproveCreditModal from '@/components/Requests/Admin/Leave/LeaveCredit/ApproveCreditModal.vue';
import Create from '@/components/Requests/Dev/Leave/LeaveCredit/create.vue';
export default {
    components: {
        Create,
        // ApproveCreditModal,
        ViewRequestModal,
        UpdateRequestModal,
        DeleteRequestModal,
    },
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        requestsDataList: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        totalPages: {
            type: Number,
            default: 0,
        },
        limit: {
            type: Number,
            default: 1,
        },
        totalPerPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            currentCredit: {
                id: null,
                status: null,
                approved_credit: 0,
            },
            tblColumns: [
                {
                    title: 'Date Created',
                    key: 'createdAt',
                    width: 120,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.createdAt ? moment(params.row.createdAt).utc().format('YYYY-MM-DD') : ''
                        );
                    },
                },
                ...(this.isAdmin
                    ? [
                          {
                              title: 'Name',
                              width: 180,
                              render: (h, params) => {
                                  return h('div', params.row.user.name);
                              },
                          },
                      ]
                    : []),
                {
                    title: 'type',
                    key: 'type',
                    width: 180,
                    render: (h, params) => {
                        return h('div', params.row.type);
                    },
                },
                {
                    title: 'Credit Requested',
                    key: 'credit_requested',
                    width: 180,
                    render: (h, params) => {
                        return h('div', params.row.credit_requested);
                    },
                },
                {
                    title: 'Approved Credit',
                    key: 'credit_approved',
                    width: 200,
                    render: (h, params) => {
                        return h('div', params.row.credit_approved);
                    },
                },
                {
                    title: 'Status',
                    key: 'status',
                    minWidth: 200,
                    render: (h, params) => {
                        // return h('div', params.row.status);
                        const status = params.row.status;
                        let label = '',
                            className = 'statusLabel';
                        const upperCaseStats = status.toString().toUpperCase();
                        switch (status.toString().trim()) {
                            case 'pending':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'rejected':
                                label = upperCaseStats;
                                className = `${className} danger`;
                                break;
                            case 'cancelled':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'approved':
                                label = upperCaseStats;
                                className = `${className} success`;
                                break;

                            default:
                                break;
                        }
                        return h(
                            'div',
                            {
                                class: `${className}`,
                            },
                            label
                        );
                    },
                },
                {
                    title: 'Actions',
                    minWidth: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    {
                                        class: 'pt-btn-3',
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-eye',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    // this.$refs.ViewRequest.openModal(params.row, false);
                                                    // console.log(params.row);
                                                    this.showConfirmationProcessRequest({
                                                        details: params.row,
                                                        isAdmin: this.isAdmin,
                                                    });
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Update' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            !this.isAdmin && params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-4 warning'
                                                : 'btn-hide'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-create',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    // this.handleProcessRequest({
                                                    //     id: params.row._id,
                                                    //     credit_approved: params.row.credit_requested,
                                                    //     status: 'approved',
                                                    // });
                                                    console.log('test update button');
                                                    this.showUpdateRequest({
                                                        details: params.row,
                                                    });
                                                },
                                            },
                                        }),
                                    ],
                                    'Update'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Approve' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            this.isAdmin && params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-4'
                                                : 'btn-hide'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-checkmark',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.handleProcessRequest({
                                                        id: params.row._id,
                                                        credit_approved: params.row.credit_requested,
                                                        status: 'approved',
                                                    });
                                                },
                                            },
                                        }),
                                    ],
                                    'approve'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Reject' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            this.isAdmin && params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-1'
                                                : 'btn-hide'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-close',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.handleProcessRequest({
                                                        id: params.row._id,
                                                        status: 'rejected',
                                                        credit_approved: 0,
                                                    });
                                                },
                                            },
                                        }),
                                    ],
                                    'reject'
                                ),
                            ]),
                            h('Tooltip', { props: { placement: 'top', content: 'Cancel' } }, [
                                h(
                                    'Button',
                                    {
                                        class: `${
                                            !this.isAdmin && params.row.status && params.row.status.includes('pending')
                                                ? 'pt-btn-1'
                                                : 'pt-btn-1 btn-hide'
                                        }`,
                                    },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'ios-remove-circle-outline',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.DeleteRequest.openModal(params.row, 'cancel');
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'cancel'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },
    methods: {
        async handleProcessRequest({ id, status, credit_approved }) {
            try {
                await adminProcessLeaveCreditForApproval({ id, status, credit_approved });
                this.$Message.success({ content: `Leave credit ${status}`, duration: 5 });
                this.handleReloadList();
            } catch (err) {
                this.$Message.error({ content: 'err.message', duration: 3 });
            }
        },
        async showConfirmationProcessRequest({ details, isAdmin = false }) {
            this.currentCredit = details;
            if (isAdmin) this.$refs.approveCreditModal.openModalForAdminProcessingLeaves(details);
            else {
                this.$refs.approveCreditModal.openModalForReadOnly(this.currentCredit);
            }
        },
        async showUpdateRequest({ details }) {
            this.$refs.approveCreditModal.openModalForEditing(details);
        },
        handleReloadList() {
            this.$emit('reload');
        },
        handleOnpageChanged(page) {
            this.$emit('on-page-change', page);
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.requestsList button {
    width: 25px;
    margin-left: 10px;
}
.requestsList i.ivu-icon {
    margin-left: -12.5px;
}
.btn-disable {
    cursor: not-allowed;
    pointer-events: none;

    /*Button disabled - CSS color class*/
    color: #c0c0c0;
    background-color: #ffffff;
}

.btn-hide {
    display: none;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
    font-weight: bold;
}
.primary {
    background: #211841;
}
.secondary {
    background: #828899;
}
</style>
