<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Leaves"
            width="600"
            no-footer
            :closable="closable"
            class="viewTimeDispute"
            :before-close="handleReset"
        >
            <Row justify="start" align="top">
                <Col span="36">
                    <div v-if="details">
                        <Row>
                            <Col span="20">
                                <span>Date Created</span>
                            </Col>
                            <Col span="20">Status</Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="12">
                                <span>{{ getDateOnly(details.createdAt) }}</span>
                            </Col>
                            <Col span="20"
                                ><div :class="details.className">{{ details.status }}</div>
                            </Col>

                            <Col span="20"
                                ><span>{{ details.user.name }}</span></Col
                            >
                        </Row>

                        <Row>
                            <Col span="8"><span>Date Start</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="20"
                                ><span>{{ getDateOnly(details.requestedStart) }}</span></Col
                            >
                        </Row>

                        <Row>
                            <Col span="8"><span>Date End</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="20"
                                ><span>{{ getDateOnly(details.requestedEnd) }}</span></Col
                            >
                        </Row>

                        <Row>
                            <Col span="8"><span>Leave Type</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="8"
                                ><span>{{ details.requestedData.type }}</span></Col
                            >
                        </Row>

                        <Row>
                            <Col span="8"><span>Days to be consumed</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="8"
                                ><span>{{ getDaysDifference(details.requestedStart, details.requestedEnd) }}</span></Col
                            >
                        </Row>

                        <Row>
                            <Col span="8"><span>Reason</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="8"
                                ><span>{{ details.reason }}</span></Col
                            >
                        </Row>

                        <div>
                            <Row>
                                <Col span="8"><span>Status</span></Col>
                            </Row>
                            <Row style="margin-bottom: 50px">
                                <Col span="8"
                                    ><span>{{ details.status }}</span></Col
                                >
                            </Row>
                        </div>
                        <div class="text-left" v-if="mainRole && isPending">
                            <Button
                                v-if="mainRole && isPending"
                                class="pt-btn-4"
                                :loading="isLoading"
                                @click="handleRequest('approved')"
                            >
                                APPROVE
                            </Button>
                            <Button
                                v-if="mainRole && isPending"
                                class="pt-btn-1"
                                :loading="isLoading"
                                @click="handleRequest('rejected')"
                            >
                                REJECT
                            </Button>
                            <Button class="pt-btn-2" :loading="isLoading" @click="handleCancel"> CLOSE </Button>
                        </div>
                    </div>
                    <p v-else class="text-center" style="width: 600px">No Data</p>
                </Col>
            </Row>
        </Modal>
    </div>
</template>
<script>
import moment from 'moment';
import { getRequestRecordByUserAndAttendanceId, requestLeaveProcess } from '@/services/requests.services';
import { timeConversion } from '../../../../../../utility/helper';
export default {
    components: {},
    data() {
        return {
            isLoading: false,
            details: null,
            showModal: false,
            closable: false,
            attendanceId: null,
            userId: null,
            mainRole: localStorage.getItem('mainRole') === 'admin',
            isPending: false,
        };
    },
    methods: {
        getDateOnly(datetime) {
            return moment(datetime).format('yyyy-MM-DD');
        },
        getDaysDifference(from, to) {
            const days = Math.abs(moment(from).startOf('day').diff(moment(to).endOf('day'), 'days'));
            return days + 1;
        },
        async handleGetRequestRecordByAttendanceId(data) {
            return await getRequestRecordByUserAndAttendanceId(this.attendanceId, this.userId, {
                requestedStart: timeConversion(moment.utc(data.createdAt).format('DD-MMM-YYYY'), '00:00'),
            });
        },
        async openModal(data) {
            console.log('data2: ', data);
            this.userId = data.user._id;
            this.details = data;
            this.isPending = data.status === 'pending';
            // try {
            //     if (fromShiftTable) {
            //         // const result = await this.handleGetRequestRecordByAttendanceId(data);
            //         // const status = this.handleStatusLabel(result.data[0]);
            //         this.details = {
            //             _id: result.data[0]._id,
            //             employee: data.user.name,
            //             dateRequested: moment(result.data[0].requestedStart).utc().format('DD-MMM-yyyy'),
            //             className: status.className,
            //             statLabel: status.label,
            //             reason: result.data[0].reason,
            //         };
            //         this.isPending = result.data[0].status && result.data[0].status.includes('pending');
            //     } else {
            //         const status = this.handleStatusLabel(data);
            //         this.details = {
            //             _id: data._id,
            //             employee: data.user.name,
            //             dateRequested: moment(data.requestedStart).utc().format('DD-MMM-yyyy'),
            //             className: status.className,
            //             statLabel: status.label,
            //             reason: data.reason,
            //         };
            //         this.isPending = data.status && data.status.includes('pending');
            //     }
            //     this.showModal = true;
            // } catch (err) {
            //     this.$Message.error('Request record not found');
            // }
            this.showModal = true;
        },

        handleStatusLabel(data) {
            const status = data.status;
            let label = '',
                className = 'statusLabel';
            const upperCaseStats = status.toString().toUpperCase();
            switch (status.toString().trim()) {
                case 'pending':
                    label = upperCaseStats;
                    className = `${className} warning`;
                    break;
                case 'rejected':
                    label = upperCaseStats;
                    className = `${className} danger`;
                    break;
                case 'approved':
                    label = upperCaseStats;
                    className = `${className} success`;
                    break;

                default:
                    break;
            }
            return { label, className };
        },

        handleCancel() {
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },
        handleReset() {
            this.showModal = false;
        },

        handleRequest(status) {
            this.isLoading = true;
            const data = {
                id: this.details._id,
                status: status,
            };
            requestLeaveProcess(data)
                .then((res) => {
                    if (res && res.data.success) {
                        this.$Message.success(`Request has been ${status}`);
                        this.isLoading = false;
                        this.$emit('create');
                        this.showModal = false;
                    }
                })
                .catch((err) => {
                    if (err.data.statusCode === 400 && err.data.message === 'request not found') {
                        this.$Message.error('Request not found');
                    } else if (err.data.statusCode === 403 && err.data.message === 'request already processed') {
                        this.$Message.error(
                            'You`ve already processed this request time dispute. Please do check it again.'
                        );
                    } else {
                        this.$Message.error('Failed to process request time dispute');
                    }
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style>
.viewTimeDispute .ivu-modal-body {
    padding: 16px;
}
.viewTimeDispute .ivu-modal-footer {
    display: none;
}
.viewTimeDispute .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.viewTimeDispute button {
    font-weight: bold;
    margin-left: 10px;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
    font-weight: bold;
}
</style>
