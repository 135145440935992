import { axiosInstance, getAuthorizationHeaders } from '@/services/base';

const url = `users/leave-credit`;
const urlAdmin = `admin/leave-credit`
export function submitLeaveCreditForApproval(data) {
    return axiosInstance.post(`${url}/submit`, data, getAuthorizationHeaders());
}
export function getSubmittedLeaveCreditList({ page = 1, limit = 1, filter = {} }) {
    return axiosInstance.get( `${url}/get-list`, { params: {page, limit, filter}, ...getAuthorizationHeaders() });
}

export function cancelSubmittedLeaveCredit(id) {
    return axiosInstance.post( `${url}/cancel`, {id: id}, getAuthorizationHeaders() );
}

export function updateSubmittedLeaveCredit({ id, data }) {
    return axiosInstance.put(`${url}/update-credit`, { id: id, ...data }, getAuthorizationHeaders());
}


export function adminProcessLeaveCreditForApproval(data) {
    return axiosInstance.post(`${urlAdmin}/process`, data, getAuthorizationHeaders());
}
export function adminGetSubmittedLeaveCreditList({ page = 1, limit = 1, filter = {} }) {
    return axiosInstance.get( `${urlAdmin}/get-list`, { params: {page, limit, filter}, ...getAuthorizationHeaders() });
}

export function getEmployeeAvailableCredits({ user }) {
    return axiosInstance.get(`${urlAdmin}/get-available-employee-credits`, {
        params: { user },
        ...getAuthorizationHeaders(),
    });
}

export function adminDoGrantEmployeeCredit({ user, type, credit_approved }) {
    return axiosInstance.post(`${urlAdmin}/force-grant`, { user, type, credit_approved }, getAuthorizationHeaders());
}