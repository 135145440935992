<template>
    <div>
        <Header
            :selected-dates="selectedDates"
            :is-admin="isAdmin"
            @status-changed="handleStatusChanged"
            @date-change="handleDateChange"
            @selected-user="handleUserSelected"
        />

        <Col v-if="!isAdmin" class="d-flex-center">
            <Button class="pt-btn-3 d-flex-center text-center" style="margin: 10px" @click="openModal">
                <Icon type="md-add" size="20" /> New Leave Credit Request
            </Button>
        </Col>

        <Col v-if="isAdmin" class="d-flex-right">
            <Button class="pt-btn-3 d-flex-center text-center" style="margin: 10px" @click="openModal">
                <Icon type="md-add" size="20" /> Grant Leave Credit
            </Button>
        </Col>

        <List
            :total-count="totalCount"
            :current="currentPage"
            :total-pages="totalPages"
            :limit="limit"
            :requests-data-list="tblData"
            :is-admin="isAdmin"
            @on-page-change="handleOnPageChanged"
            @reload="handleReload"
        />
        <CreateModal ref="CreateRequest" :is-admin="isAdmin" @create="handleReload"></CreateModal>
    </div>
</template>

<script>
import CreateModal from '@/components/Requests/Dev/Leave/LeaveCredit/create.vue';
import Header from '@/components/Requests/Dev/Leave/LeaveCredit/header.vue';
import List from '@/components/Requests/Dev/Leave/LeaveCredit/list.vue';
import {
    adminGetSubmittedLeaveCreditList,
    getSubmittedLeaveCreditList,
} from '@/components/Requests/Dev/Leave/LeaveCredit/services/credit.services';
export default {
    components: {
        Header,
        List,
        CreateModal,
    },
    props: {
        isAdmin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedStatus: this.$route.query.status,
            selectedUser: localStorage.getItem('user_id'),
            currentPage: 1,
            limit: 2,
            totalCount: 0,
            totalPages: 0,
            filter: {},
            tblData: [],
            selectedDates: null,
            employeeList: [],
            selectedEmployee: null,
        };
    },
    created() {
        if (this.$route.query.status) {
            this.$emit('status-changed', this.selectedStatus);
        }
    },
    mounted() {
        this.getList({ page: 1, limit: this.limit });
        //populating dropdown employee list
        if (this.isAdmin) {
            this.handleGetAllEmployees();
        }
    },
    methods: {
        handleReload() {
            this.$emit('reload');
            this.getList({ page: this.currentPage, limit: this.limit });
            //populating dropdown employee list
            if (this.isAdmin) {
                this.handleGetAllEmployees();
            }
        },
        openModal() {
            this.$refs.CreateRequest.openModal({ userId: this.selectedUser }, false);
        },
        handleUserSelected(user) {
            console.log('handleUserSelected: ', user);
            this.filter = {
                ...(user && { user: user._id }),
                ...(this.filter.status && { status: this.filter.status }),
                ...(this.filter.createdAt && { createdAt: this.filter.createdAt }),
            };
            this.currentPage = 1;
            this.getList({ page: this.currentPage, limit: this.limit, filter: this.filter });
        },

        handleStatusChanged(stats) {
            this.filter = {
                status: stats,
                ...(!this.isAdmin && { user: this.selectedUser }),
                ...(this.isAdmin && this.filter.user && { user: this.filter.user }),
                ...(this.filter.createdAt && { createdAt: this.filter.createdAt }),
            };
            this.currentPage = 1;
            this.getList({ page: this.currentPage, limit: this.limit, filter: this.filter });
        },
        handleDateChange(date) {
            this.filter = {
                ...(!this.isAdmin && { user: this.selectedUser }),
                ...(this.filter.status && { status: this.filter.status }),
                ...(this.isAdmin && this.filter.user && { user: this.filter.user }),
                ...(date.from && { createdAt: { $gte: date.from, $lte: date.to } }),
            };
            this.currentPage = 1;
            this.getList({ page: this.currentPage, limit: this.limit, filter: this.filter });
        },
        handleOnPageChanged(page) {
            this.currentPage = page;
            this.getList({
                page,
                filter: {
                    ...(!this.isAdmin && { user: this.selectedUser }),
                    ...(this.isAdmin && this.filter.user && { user: this.filter.user }),
                    ...(this.filter.status && { status: this.filter.status }),
                },
                limit: this.limit,
            });
        },
        async getList({ page = 1, limit = 1, filter = {} }) {
            try {
                if (!this.isAdmin) {
                    const { data } = await getSubmittedLeaveCreditList({
                        page,
                        limit,
                        filter: { ...filter, ...(!this.isAdmin && { user: this.selectedUser }) },
                    });

                    this.tblData = data.data;
                    this.totalCount = data.totalCount;
                } else {
                    const { data } = await adminGetSubmittedLeaveCreditList({
                        page,
                        limit,
                        filter: { ...filter, ...(!this.isAdmin && { user: this.selectedUser }) },
                    });

                    this.tblData = data.data;
                    this.totalCount = data.totalCount;
                }
            } catch (err) {
                console.log('trying to get list leave credit: ', err);
            }
        },
    },
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
    .requests-filter {
        .ivu-row {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .ivu-col-span-xs-2 {
                margin: 5px 0;
            }
            .filter-btn button {
                width: 100%;
            }
        }
    }
}
</style>
