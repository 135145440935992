<script setup>
import { getUsers } from '@/services/users.services';

export default {
    data() {
        return {
            employeeList: [],
        };
    },
    props: {
        user: {
            type: String,
            default: '',
        },
        listDisabled: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.handleGetAllEmployees();
    },
    methods: {
        handleGetAllEmployees() {
            this.isLoading = true;
            getUsers()
                .then((res) => {
                    if (res.data.length) {
                        let result = res.data.filter(
                            (x) => x.mainRole !== 'client' && x.mainRole !== 'admin' && x.isArchived === false
                        );

                        this.employeeList = result.map((i) => {
                            return {
                                value: i._id,
                                label: i.name,
                                name: i.name,
                                details: i,
                                key: i._id,
                                _id: i._id,
                            };
                        });
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get users');
                    this.isLoading = false;
                });
        },
        handleUserChanged(user) {
            const employeeIndex = this.employeeList.findIndex((e) => e.value === user);
            console.log('user: ', this.employeeList[employeeIndex]);
            this.$emit('selected-user', this.employeeList[employeeIndex]?.details || null);
        },
    },
};
</script>

<template>
    <div>
        <Select
            :disabled="listDisabled"
            v-model="user"
            :value="$route.query.user"
            filterable
            clearable
            placeholder="All Employees"
            @on-change="handleUserChanged"
        >
            <Option v-for="item in employeeList" :key="item.key" :value="item.value">
                {{ item.name }}
            </Option>
        </Select>
    </div>
</template>

<style scoped lang="scss"></style>
